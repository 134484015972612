.main {
  position:relative;
  height: 100%;
  width: 100%;
}

header {
  text-align: right;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  background-color: #1ab1ac;
  color: #fff;
  padding: 10px;
}

.content {
  position: fixed;
  top: 40px;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow-y: scroll;
}